<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="4">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-form-input
                id="address"
                placeholder="Address"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <v-select
                v-model="selected.listingType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="value"
                :options="options.listingType"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <v-select
                v-model="selected.availability"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="value"
                :options="options.availability"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <v-select
                v-model="selected.homeType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="value"
                :options="options.homeType"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col cols="2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-2"
            @click="updateMap()"
          >
            Apply
          </b-button>
        </b-col>
      </b-row>

    </b-form>
    <l-map
      ref="myMap"
      :zoom="zoom"
      :center="center"
      @ready="mapOnReady()"
      @dragend="updateMap()"
    >

      <l-tile-layer :url="url" />
      <v-marker-cluster>
        <l-marker
          v-for="listing in listings"
          :key="listing['listing-id']"
          :lat-lng="[listing.location.position.coordinates[1], listing.location.position.coordinates[0]]"
        >
          <l-popup>
            <b-card
              :img-src="listing.media['image-urls'][0]"
              img-top
              img-alt="card img"
              :title="showPrice(listing.financial)"
              class="mb-3"
            >
              <b-card-text>
                {{ showAddress(listing.location) }}
              </b-card-text>
              <b-card-text>
                {{ listing.attributes.bedrooms }} Beds - {{ listing.attributes.bathrooms }} Baths - {{ listing.attributes['square-footage'].size || 'Unknown' }} {{ listing.attributes['square-footage'].unit }}
              </b-card-text>
              <b-card-text>
                <small class="text-muted">{{ listing.timeline['modified-at'] }}</small>
              </b-card-text>
            </b-card>
          </l-popup>
        </l-marker>
      </v-marker-cluster>
    </l-map>
  </div>

</template>

<script>
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import {
  LMap, LTileLayer, LPopup, LMarker,
} from 'vue2-leaflet'
// eslint-disable-next-line no-unused-vars
import moment from 'moment'
import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet'
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BRow, BCard, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

export default {
  components: {
    LMap,
    LTileLayer,
    LPopup,
    LMarker,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
    BButton,
    vSelect,
    BCard,
    BCardText,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      center: [43.780918, -79.421371],
      listings: [],
      selected: {
        listingType: { value: 'Sale' },
        availability: { value: 'Active' },
        homeType: { value: 'Detached' },
      },
      options: {
        listingType: [{ value: 'Sale' }, { value: 'Lease' }],
        availability: [{ value: 'Active' }, { value: 'Unavailable' }],
        homeType: [{ value: 'Detached' }, { value: 'Semi-detached' }, { value: 'Townhouse' }, { value: 'Condo/Apartment' }],
      },
    }
  },
  mounted() {
  },
  methods: {
    mapOnReady() {
      this.listings = []
      this.map = this.$refs.myMap.mapObject
    },
    showPrice(financial) {
      if (financial.sold > 0) {
        return `$${financial.sold.toLocaleString()}`
      }
      return `$${financial.listed.toLocaleString()}`
    },
    showAddress(location) {
      if (location['unit-number']) {
        return `${location['unit-number']} ${location['full-address-short'].toUpperCase()}`
      }
      return location['full-address-short'].toUpperCase()
    },
    async getListings(geoLocation, extraParams) {
      const filter = {
        where: {
          'location.position': {
            geoWithin: {
              $geometry: {
                type: 'Polygon',
                coordinates: [[
                  [parseFloat(geoLocation.west), parseFloat(geoLocation.south)],
                  [parseFloat(geoLocation.west), parseFloat(geoLocation.north)],
                  [parseFloat(geoLocation.east), parseFloat(geoLocation.north)],
                  [parseFloat(geoLocation.east), parseFloat(geoLocation.south)],
                  [parseFloat(geoLocation.west), parseFloat(geoLocation.south)],
                ]],
              },
            },
          },
          'metadata.source': 'WEB',
          'availability.status': extraParams.availability[0] || 'a',
          'attributes.class': 'residential',
          'attributes.type': null,
          'attributes.ownership-type': null,
          'listing-type': extraParams.listingType || 'sale',
        },
        order: 'timeline.modified-at DESC',
        limit: 250,
        fields: {
          'listing-id': true,
          media: true,
          'listing-type': true,
          location: true,
          financial: true,
          id: true,
          timeline: true,
          attributes: true,
        },
      }

      if (extraParams.homeType === 'condo/apartment') {
        filter.where['attributes.ownership-type'] = 'condominium'
        delete filter.where['attributes.type']
      } else {
        filter.where['attributes.ownership-type'] = 'freehold'
        filter.where['attributes.type'] = extraParams.homeType
      }
      console.log(JSON.stringify(filter))
      const res = await fetch(`https://mongocms-subsystems-listing-ms.local.fastdns.io/api/listings?filter=${JSON.stringify(filter)}`, {
        headers: {
          'x-api-key': 'mongocms',
        },
      })
      const data = await res.json()
      return data
    },
    async updateMap() {
      const { _southWest, _northEast } = this.map.getBounds()
      const geoLocation = {
        south: _southWest.lat,
        west: _southWest.lng,
        north: _northEast.lat,
        east: _northEast.lng,
      }
      const extraParams = {
        homeType: this.selected.homeType.value.toLowerCase(),
        listingType: this.selected.listingType.value.toLowerCase(),
        availability: this.selected.availability.value.toLowerCase()[0],
      }
      const listings = await this.getListings(geoLocation, extraParams)
      this.listings = listings.filter(listing => listing.location.position.type === 'Point' && listing.location.position.coordinates !== [0, 0])
    },
  },
}
</script>

<style lang="scss">
.vue2leaflet-map{
  &.leaflet-container{
    height: 800px
  }
}
</style>
